// ServiceWorkerMessenger.js
'use strict';

import env from '../resource/env.js';
import WorkerMessenger from '../resource/WorkerMessenger.js';
import FallbackServiceWorker from '../serviceWorker/FallbackServiceWorker.js';
import unRegisterServiceWorker from '../resource/unRegisterServiceWorker.js';
import Delay from '../resource/Delay.js';
import { workerMessenger as workerMessengerDebug } from '../resource/debug.js';
import { getIsInBrowserMainThread } from '../resource/getJsEnvironment.js';

const isInBrowserMainThread = getIsInBrowserMainThread();
const workerMessengerLog = workerMessengerDebug.extend('log');
const workerMessengerErrorLog = workerMessengerDebug.extend('error');

export const messengerMessageIdKey = 'swMessageId';
let urlTimeout = null;
if (isInBrowserMainThread) {
  urlTimeout = new URL(window.location.href).searchParams.get(
    'serviceWorkerTimeout'
  );
}

export const defaultGetInstanceArgument = {
  messengerArguments: {
    name: 'serviceWorker',
    scope: {},
    messageIdKey: messengerMessageIdKey,
  },
  registerArguments: [
    isInBrowserMainThread ? window.serviceWorkerPath : '',
    { scope: '/' },
  ],
  fallbackTimeoutMsec: urlTimeout || env.SERIVCE_WORKER_FALLBACK_TIMEOUT_MSEC,
};

/**
 * ServiceWorkerMessenger extends WorkerMessenger, adding event queue and fallback feature to interfaces.
 */
class ServiceWorkerMessenger extends WorkerMessenger {
  /**
   * Create a serviceWorkerMessenger.
   * @param {string} {messengerArguments.name} - Name for worker messenger, mostly for logging.
   * @param {object} {[messengerArguments.scope={}]} - Scope for peer interfaces to run.
   * @param {string} {messengerArguments.messageIdKey} - Message id property name on message object, to identify message from other purpose events.
   * @param {array} {registerArguments} - arguments for navigator.serviceWorker.register()
   * @param {number} {fallbackTimeoutMsec = 5000} - timeout to fallback to FallbackServiceWorker
   */
  constructor({
    messengerArguments: { name, scope, messageIdKey },
    registerArguments,
    fallbackTimeoutMsec = 5000,
  }) {
    super({
      name,
      scope,
      messageIdKey,
      listenTarget: globalThis.navigator.serviceWorker,
      getMessageTarget: async () => null,
    });

    this.fallbackTimeoutMsec = fallbackTimeoutMsec;

    super.getMessageTarget = this._getMessageTarget;

    this._callingQueue = [];

    this.isWaitingControllerChange = true;

    this._initServiceWorker({ registerArguments, fallbackTimeoutMsec });
  }

  /**
   * Set value/object to the message target scope
   * @param {array} {selectPath} - node path from scope toward the set target, missing nodes will be create
   * @param {any} {value} - value to be set, will be transform by safeStructuredClone()
   * @param {Object} {[options={}]} - options
   */
  async set(input = {}) {
    if (input.options?.isCritical && this.isWaitingControllerChange) {
      this.log('set() push call', { input });
      this._callingQueue.push({ interface: 'set', input });
    }
    return super.set(input);
  }

  /**
   * Call function in the message target scope, with Function.prototype.apply() pattern
   * @param {array} {selectPath} - node path from scope toward the target function
   * @param {array} {thisSelectPath} - node path from scope toward the 'this' target for apply
   * @param {array} {args} - apply(_, args), each value will be transformed by safeStructuredClone()
   * @param {Object} {[options={}]} - options
   */
  async apply(input = {}) {
    if (input.options?.isCritical && this.isWaitingControllerChange) {
      this.log('apply() push call', { input });
      this._callingQueue.push({ interface: 'apply', input });
    }
    return super.apply(input);
  }

  async _getMessageTarget() {
    return (
      this._fallbackMessageTarget ||
      globalThis.navigator.serviceWorker.controller
    );
  }

  /**
   * Block destroying the service worker messenger
   */
  destroy() {
    throw new Error("can't destroy a singleton");
  }

  static instance = null;

  /**
   * Create a serviceWorkerMessenger.
   * @param {string} {messengerArguments.name} - Name for worker messenger, mostly for logging.
   * @param {object} {[messengerArguments.scope={}]} - Scope for peer interfaces to run.
   * @param {string} {messengerArguments.messageIdKey} - Message id property name on message object, to identify message from other purpose events.
   * @param {array} {registerArguments} - arguments for navigator.serviceWorker.register()
   * @param {number} {fallbackTimeoutMsec = 5000} - timeout to fallback to FallbackServiceWorker
   * @return {ServiceWorkerMessenger} singleton service worker messenger
   */
  static getInstance = ({
    messengerArguments = defaultGetInstanceArgument.messengerArguments,
    registerArguments = defaultGetInstanceArgument.registerArguments,
    fallbackTimeoutMsec = defaultGetInstanceArgument.fallbackTimeoutMsec,
  } = defaultGetInstanceArgument) => {
    const log = workerMessengerLog.extend('getInstance');
    const errorLog = workerMessengerErrorLog.extend('getInstance');
    if (!this.instance) {
      try {
        log('getInstance()', {
          messengerArguments,
          registerArguments,
          fallbackTimeoutMsec,
        });
        this.instance = new ServiceWorkerMessenger({
          messengerArguments,
          registerArguments,
          fallbackTimeoutMsec,
        });
      } catch (error) {
        errorLog('getInstance() error', {
          error,
          messengerArguments,
          registerArguments,
          fallbackTimeoutMsec,
        });
      }
    }
    return this.instance;
  };

  _flushQueuedCalls() {
    const log = this.log.extend('_flushQueuedCalls');
    const errorLog = this.errorLog.extend('_flushQueuedCalls');

    log('start flushing');
    while (this._callingQueue.length) {
      const call = this._callingQueue.shift();
      log('flushing', { call, remain: this._callingQueue.length });
      try {
        // the queued calls will mostly be promises, need to use
        // .catch() to make sure errors can be caught,
        // not using `await` is to prevent blocking promises
        super[call.interface](call.input).catch(error => {
          errorLog('error', { error, call });
        });
      } catch (error) {
        errorLog('error', { error, call });
      }
    }
    log('finish flushing');
  }

  _getControllerChangePromise({ inputUrl }) {
    const log = this.log.extend('_getControllerChangePromise');
    return new Promise(resolve => {
      log('start');
      const listener = event => {
        const scriptURL = navigator.serviceWorker.controller?.scriptURL;
        log('service worker changed', {
          inputUrl: inputUrl.href,
          scriptURL,
          event,
        });
        if (inputUrl.href === scriptURL) {
          log('service worker updated, resolve', {
            inputUrl: inputUrl.href,
            scriptURL,
            event,
          });
          navigator.serviceWorker.removeEventListener(
            'controllerchange',
            listener
          );
          resolve(event);
        } else {
          log('service worker changed with different url, skip resolve', {
            inputUrl: inputUrl.href,
            scriptURL,
            event,
          });
        }
      };
      navigator.serviceWorker.addEventListener('controllerchange', listener);
    });
  }

  async _registerServiceWorker(registerArguments) {
    const log = this.log.extend('_registerServiceWorker');
    log('init', { registerArguments });
    // use !Array to use old unregister logic first.
    if (!navigator.serviceWorker.controller && !Array) {
      log('missing controller');
      const registration = await navigator.serviceWorker.getRegistration();
      // sync controller again after await
      const controller = navigator.serviceWorker.controller;
      log('getRegistration()', { registration });

      let refreshBy = null;
      if (!controller && !registration?.active) {
        // sometimes we still got 'new' on hard-reload,
        // but this case could be auto recovered anyways
        refreshBy = 'new';
      } else if (!controller && registration?.active) {
        refreshBy = 'hard-reload';
      } else if (controller && !registration?.active) {
        refreshBy = 'unknown';
      } else {
        refreshBy = 'reload';
      }
      log('check refreshBy', { refreshBy });

      if (refreshBy === 'hard-reload') {
        log('unregister start', { registerArguments, refreshBy });
        await unRegisterServiceWorker();
        log('unregister done', { registerArguments, refreshBy });
      }
    }

    log('register start', { registerArguments });
    const registration = await navigator.serviceWorker.register(
      ...registerArguments
    );
    log('register done', { registerArguments, registration });
    return registration;
  }

  _applyFallbackServiceWorker() {
    const log = this.log.extend('_applyFallbackServiceWorker');
    log("time's up, start making fallback", {
      superListenTarget: super.listenTarget,
      thisListenTarget: this.listenTarget,
      superEventHandler: super._thisEventHandler,
      thisEventHandler: this._thisEventHandler,
    });
    this._fallbackEventTarget = new EventTarget();
    this._fallbackMessageTarget = {
      postMessage: message => {
        const event = new CustomEvent('message', { detail: message });
        log('postMessage()', {
          message,
          eventTarget: this._fallbackEventTarget,
          event,
        });
        this._fallbackEventTarget.dispatchEvent(event);
      },
    };

    this._fallbackServiceWorker = FallbackServiceWorker.getInstance({
      workerMessengerArguments: {
        listenTarget: this._fallbackEventTarget,
        messageIdKey: this.messageIdKey,
      },
    });

    this.listenTarget.removeEventListener('message', this._thisEventHandler);
    this.listenTarget = this._fallbackServiceWorker.eventTarget;
    this.listenTarget.addEventListener('message', this._thisEventHandler);

    log('bind fallback service worker done', {
      this: this,
      fallbackEventTarget: this._fallbackEventTarget,
      fallbackServiceWorker: this._fallbackServiceWorker,
      thisListenTarget: this.listenTarget,
      thisFallbackEventHandler: this._thisFallbackEventHandler,
    });
    return this._fallbackServiceWorker;
  }

  async _initServiceWorker({ registerArguments, fallbackTimeoutMsec } = {}) {
    const log = this.log.extend('_initServiceWorker');
    const errorLog = this.errorLog.extend('_initServiceWorker');

    if (!isInBrowserMainThread) {
      throw new Error(
        'ServiceWorkerMessenger not invoked in browser main thread.'
      );
    }
    if (!navigator.serviceWorker) {
      throw new Error('service worker is not supported.');
    }

    const [inputScriptUrl] = registerArguments || [];
    if (!inputScriptUrl) {
      throw new Error('ServiceWorkerMessenger create without script url.');
    }
    const inputUrl = new URL(inputScriptUrl, location.origin);

    try {
      const currentScriptUrl = navigator.serviceWorker.controller?.scriptURL;

      if (inputUrl.href === currentScriptUrl) {
        log('service worker is up to date', {
          currentScriptUrl,
          inputScriptUrl,
        });
      } else {
        log('service worker need to update', {
          currentScriptUrl,
          inputScriptUrl,
        });
        const promiseRaceResult = await Promise.race([
          Promise.all([
            this._getControllerChangePromise({ inputUrl }),
            this._registerServiceWorker(registerArguments),
          ]),
          new Delay().promise(fallbackTimeoutMsec).then(() => null),
        ]);
        log('done service worker update', {
          currentScriptUrl,
          inputScriptUrl,
          registerArguments,
          promiseRaceResult,
        });

        if (null !== promiseRaceResult) {
          const registeration = await navigator.serviceWorker.ready;
          log('done service worker update ready', {
            registeration,
            serviceWorker: globalThis.navigator.serviceWorker,
            controller: globalThis.navigator.serviceWorker.controller,
          });
        } else {
          this._applyFallbackServiceWorker();
        }
        // should clear this._feedbackHandlers?
        this._flushQueuedCalls();
      }

      this.isWaitingControllerChange = false;
    } catch (error) {
      errorLog('error', { error, registerArguments });
    }
  }
}

export default ServiceWorkerMessenger;
